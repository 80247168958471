/* math */
@font-face {
  font-family: '__Kumbh_Sans_932cb8';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/843695e50a52364d-s.woff2) format('woff2');
  unicode-range: U+0302-0303, U+0305, U+0307-0308, U+0330, U+0391-03A1, U+03A3-03A9, U+03B1-03C9, U+03D1, U+03D5-03D6, U+03F0-03F1, U+03F4-03F5, U+2034-2037, U+2057, U+20D0-20DC, U+20E1, U+20E5-20EF, U+2102, U+210A-210E, U+2110-2112, U+2115, U+2119-211D, U+2124, U+2128, U+212C-212D, U+212F-2131, U+2133-2138, U+213C-2140, U+2145-2149, U+2190, U+2192, U+2194-21AE, U+21B0-21E5, U+21F1-21F2, U+21F4-2211, U+2213-2214, U+2216-22FF, U+2308-230B, U+2310, U+2319, U+231C-2321, U+2336-237A, U+237C, U+2395, U+239B-23B6, U+23D0, U+23DC-23E1, U+2474-2475, U+25AF, U+25B3, U+25B7, U+25BD, U+25C1, U+25CA, U+25CC, U+25FB, U+266D-266F, U+27C0-27FF, U+2900-2AFF, U+2B0E-2B11, U+2B30-2B4C, U+2BFE, U+FF5B, U+FF5D, U+1D400-1D7FF, U+1EE00-1EEFF;
}
/* latin-ext */
@font-face {
  font-family: '__Kumbh_Sans_932cb8';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/fecc267594084e47-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Kumbh_Sans_932cb8';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/94dbd0a3e14e881f-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Kumbh_Sans_Fallback_932cb8';src: local("Arial");ascent-override: 93.96%;descent-override: 24.19%;line-gap-override: 0.00%;size-adjust: 104.98%
}.__className_932cb8 {font-family: '__Kumbh_Sans_932cb8', '__Kumbh_Sans_Fallback_932cb8';font-weight: 700;font-style: normal
}

